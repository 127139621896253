import React, { FC, useEffect } from 'react'

import { DATA_REPORTS_TABS } from 'config'
import { useParams } from 'react-router-dom'

import GrowingContainer from 'components/Common/GrowingContainer'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { DataContainer, IndustryReportContainer, ReportsContainer } from 'pages/Common/container'
import { getRoleFromPath, navigateTo } from 'utils/helper'
import { ampli } from 'ampli'
import { GetAttestationSummaryInputWithoutTenant } from 'store/actions/attestationSummary'
import ButtonTabsV2, { Tab } from 'components/Common/ButtonTabsV2'
import { startCase, toLower } from 'lodash'

interface IDataReportsProps {
  userId: string
  getAttestationSummaryAction: (payload: GetAttestationSummaryInputWithoutTenant) => void
}

const DataReports: FC<IDataReportsProps> = ({ userId, getAttestationSummaryAction }) => {
  let { subTab } = useParams()

  const isDataReportsEnabled = useFeatureFlag(userId, 'release-attestations-rollout')
  const isIndustryReportEnabled = useFeatureFlag(userId, 'release-industry-report')

  useEffect(() => {
    if (isDataReportsEnabled) {
      const today = new Date()
      getAttestationSummaryAction({
        timePeriod: {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
        advisorId: userId,
        includeAggregationDetails: true,
      })
    }
    // eslint-disable-next-line
  }, [isDataReportsEnabled])

  const userRole = getRoleFromPath()
  const handleChange = (activeTab: string) => {
    navigateTo(userRole, `data-reports/${activeTab}`)
    ampli.subtabViewed({ subTabName: startCase(toLower(activeTab.replace('_', ' '))) })
  }

  return (
    <>
      <GrowingContainer id="data-and-reports">
        <ButtonTabsV2 activeTab={subTab} onChange={handleChange} classes="bg-white p-8">
          <Tab
            component={<DataContainer />}
            title={DATA_REPORTS_TABS.DATA}
            name={DATA_REPORTS_TABS.DATA.toLowerCase()}
          />
          <Tab
            component={<ReportsContainer reportsEnabled={isDataReportsEnabled} />}
            title={DATA_REPORTS_TABS.REPORTS}
            name={DATA_REPORTS_TABS.REPORTS.toLowerCase()}
            isEnabled={isDataReportsEnabled}
          />
          <Tab
            component={<IndustryReportContainer />}
            title={DATA_REPORTS_TABS.INDUSTRY_REPORT}
            name={DATA_REPORTS_TABS.INDUSTRY_REPORT.toLowerCase().replace(/\s+/g, '_')}
            isEnabled={isIndustryReportEnabled}
          />
        </ButtonTabsV2>
      </GrowingContainer>
    </>
  )
}

export default DataReports
