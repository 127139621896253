import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  ClientActivityContainer,
  ClientProfileDetailsContainer,
  ClientPlaybookContainer,
  ClientBusinessDataContainer,
} from 'pages/Clients/container'
import Breadcrumb from 'components/Clients/Client/Breadcrumb'
import PlatformInfo from 'components/Clients/Client/PlatformInfo'
import Button from 'components/Common/Button'
import ProfileStatus from 'components/Clients/Client/ProfileStatus'
import UserProfileInfo from 'components/Clients/Client/UserProfileInfo'
import ClientDetailHeader from 'components/Clients/Client/ClientDetailHeader'
import { ADVISOR_STATUS, ROLES } from 'config'
import Grid from 'components/Common/Grid'
import GrowingContainer from 'components/Common/GrowingContainer'
import { getRoleFromPath, navigateTo, setActiveTabFromUrl } from 'utils/helper'
import TackleAvatarLogo from 'components/Common/TackleAvatarLogo'
import Meeting from 'components/Clients/Client/Meeting/index'
import { RootState } from 'App'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { ConfirmationModalContainer } from 'pages/Common/container'
import ButtonTabsV2, { Tab } from 'components/Common/ButtonTabsV2'

const Client = ({
  profile,
  businessProfile,
  getClientProfileAction,
  resetClientsAction,
  openConfirmationModalAction,
  resendUserInviteAction,
}) => {
  let { clientId } = useParams()
  let [activeTab, setActiveTab] = useState<string>('')
  const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false)
  const userRole = getRoleFromPath()
  const { user, openConfirmationModal } = useSelector((state: RootState) => state.user)
  const meetingRecordEnabled = useFeatureFlag(user?.id, 'release-meeting-record-rollout')

  useEffect(() => {
    setActiveTab(setActiveTabFromUrl(window.location.href))
    getClientProfileAction({ clientId })
    return () => resetClientsAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeTabHandler = (step) => {
    navigateTo(userRole, `clients/${clientId}/${step}`)
    if (!isFormDataChanged) {
      setActiveTab(step)
    }
  }

  const chatHandler = () => {
    if (window.location.href.includes(ROLES.BSO_ADVISOR)) {
      navigateTo(ROLES.BSO_ADVISOR, `chat/${clientId}`)
    } else {
      navigateTo(ROLES.BSO_ADMIN, `chat/${clientId}`)
    }
  }
  return (
    <GrowingContainer className="xs:!px-0 sm:!px-0 md:!px-6 lg:!px-12 xs:mt-4 sm:mt-auto">
      <div className="w-full">
        {/* MobileOnly */}
        <div className="xs:block sm:hidden xs:px-6">
          <div>
            {/* profile detail */}
            <ClientDetailHeader />
            <div className="flex flex-col justify-center items-center">
              <UserProfileInfo profile={profile} />
            </div>
            {/* platform start date */}
            <div className="mt-7">
              <PlatformInfo profile={profile} />
            </div>
            {/* Status */}
            <div className="mt-2">
              <ProfileStatus profile={profile} />
            </div>
            {/* Resend Invite */}
            {profile?.status === ADVISOR_STATUS.INVITED && (
              <div className="mt-2">
                <Button
                  variant="text"
                  label="Resend Invitation"
                  onClick={() =>
                    openConfirmationModalAction({
                      openConfirmationModal: true,
                      email: profile?.email,
                      name: profile?.firstName,
                    })
                  }
                />
              </div>
            )}
            <div className="xs:mb-12 sm:mb-0 mt-[24px]">
              <Button
                type="button"
                label="Chat"
                variant="primary"
                onClick={chatHandler}
                className="w-full"
              />
            </div>
          </div>
        </div>
        {/* Laptop & Tablet */}
        <div className="xs:hidden sm:block">
          <div>
            <div className="sm:block xl:hidden">
              <ClientDetailHeader />
            </div>
            {/* breadcrumbs */}
            <div className="mdl:block sm:hidden ">
              <Breadcrumb />
            </div>
          </div>
          <Grid className={'xs:px-3 xs:pb-8 sm:px-9 md:px-12  sm:pb-6 md:py-7 items-center'}>
            {/* UserLogo  */}
            <div className="col-span-5 sm:col-span-12 md:col-span-12 mdl:col-span-5 lg:col-span-5 xl:col-span-7">
              <div className="flex">
                <div>
                  <TackleAvatarLogo
                    className="bg-blue-lighter1x h-[79.8px] w-[79.8px]"
                    padding="p-3"
                    logo={businessProfile?.logo?.url}
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                  />
                </div>
                <div className=" ml-[24px] text-2xl">
                  <div className="font-semibold">
                    {`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                  </div>
                  <div>{businessProfile?.businessName || ''}</div>
                </div>
              </div>
            </div>
            <div className="col-span-2 sm:col-span-12 md:col-span-12  mdl:col-span-7 lg:col-span-7 xl:col-span-5 xs:pt-6">
              <div className="flex justify-between items-center">
                {/* Platform start date */}

                <div className="flex items-end space-x-4">
                  <div className="col-span-8">
                    <PlatformInfo profile={profile} />
                  </div>
                  {/* Status */}
                  <div className="col-span-4 ml-5">
                    <div style={{ float: 'right' }}>
                      <ProfileStatus profile={profile} />
                    </div>
                  </div>
                  {/* Resend Invite */}
                  {profile?.status === ADVISOR_STATUS.INVITED && (
                    <div>
                      <Button
                        variant="text"
                        label="Resend Invitation"
                        onClick={() =>
                          openConfirmationModalAction({
                            openConfirmationModal: true,
                            email: profile?.email,
                            name: profile?.firstName,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
                {/* ChatButton */}
                <div>
                  <Button
                    type="button"
                    label="Chat"
                    variant="primary"
                    onClick={chatHandler}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </div>

        <ButtonTabsV2 activeTab={activeTab} onChange={changeTabHandler} classes="bg-white">
          <Tab
            component={
              <ClientProfileDetailsContainer
                setActiveTab={setActiveTab}
                isFormDataChanged={isFormDataChanged}
                setIsFormDataChanged={setIsFormDataChanged}
              />
            }
            title="Details"
            name="details"
          />
          <Tab
            title="Assessments, Plays & Tools"
            name="assessmentTools"
            component={<ClientPlaybookContainer />}
          />
          <Tab
            title="Meeting Record"
            name="meetingRecord"
            component={<Meeting />}
            isEnabled={meetingRecordEnabled}
          />
          <Tab title="Activity" name="activity" component={<ClientActivityContainer />} />
          <Tab title="Data" name="data" component={<ClientBusinessDataContainer />} />
        </ButtonTabsV2>
        {openConfirmationModal && (
          <ConfirmationModalContainer
            title="Resend user invitation"
            onConfirm={() =>
              resendUserInviteAction({
                name: `${profile?.firstName} ${profile?.lastName}`,
                email: profile?.email,
                userId: profile?.id,
              })
            }
            description={`Resending the invitation will send an email to ${profile?.email}`}
            successDescription={`The invitation has been successfully resent to  ${profile?.email}`}
            onClose={() => openConfirmationModalAction({ openConfirmationModal: false })}
          />
        )}
      </div>
    </GrowingContainer>
  )
}

export default Client
