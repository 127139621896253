import { Views } from 'react-big-calendar'
import { ROLES, FORM, CYCLR_CALENDAR, TOOL_FLYOUT_OPTIONS } from 'config'
import {
  FoodIcon,
  RetailIcon,
  ServicesIcon,
  AccountsCreatedIcon,
  SignInIcon,
  MeetingScheduleIcon,
  MeetingRescheduleIcon,
} from 'components/Common/SvgIcons'
import MsPowerPointIcon from 'components/Common/SvgIcons/MsPowerPointIcon'
import MsExcelIcon from 'components/Common/SvgIcons/MsExcelIcon'
import MsWordIcon from 'components/Common/SvgIcons/MsWordIcon'
import CsvIcon from 'components/Common/SvgIcons/CsvIcon'
import VideoIcon from 'components/Common/SvgIcons/VideoIcon'
import ImageIcon from 'components/Common/SvgIcons/ImageIcon'
import GenericDocumentIcon from 'components/Common/SvgIcons/GenericDocumentIcon'
import EcommerceIcon from 'components/Common/SvgIcons/EcommerceIcon'
import ChildCareIcon from 'components/Common/SvgIcons/ChildCareIcon'
import QuickBookIcon from 'components/Common/SvgIcons/QuickBookIcon'
import SquareIcon from 'components/Common/SvgIcons/SquareIcon'
import CalendlyIcon from 'components/Common/SvgIcons/CalendlyIcon'
import PdfIcon from 'components/Common/SvgIcons/PdfIcon'
import OutLookIcon from 'components/Common/SvgIcons/OutLookIcon'
import GoogleCalendarIcon from 'components/Common/SvgIcons/GoogleCalendarIcon'
import { PillColorTheme } from 'components/Common/PillTextBox'
import { AdvisorStepper, ITimeDurationAvailabilityOptions } from 'types'
import { ICalendarList } from 'components/Calendar/types'
import InfoTipIcon from 'components/Common/SvgIcons/InfoTipIcon'
import * as storeTypes from 'store/types'

export const routes = {
  [ROLES.BSO_ADMIN]: {
    Dashboard: [{ label: 'DASHBOARD', path: '/admin/dashboard', icon: 'bx bx-home-circle' }],
  },
  [ROLES.BSO_ADVISOR]: {
    Dashboard: [{ label: 'Home', path: '/advisor/dashboard', icon: 'bx bx-home-circle' }],
  },
  [ROLES.BUSINESS_OWNER]: {
    Dashboard: [{ label: 'Home', path: '/owner/assessment', icon: 'bx bx-home-circle' }],
  },
}
export const advisorStepper: AdvisorStepper[] = [
  {
    title: 'Welcome Advisor',
    value: 'welcome-advisor',
  },
  { title: 'Sms Subscription', value: 'sms-subscription' },
  { title: 'Sms Unsubscribe', value: 'sms-unsubscription' },
  {
    title: 'Availability Info',
    value: 'availability-info',
  },
  {
    title: 'Set Availability',
    value: 'set-availability',
  },
  {
    title: 'Set up Calendar',
    value: 'set-up-calendar',
  },
  {
    title: 'Calendar Link',
    value: 'calendar-link',
  },
  {
    title: 'Success',
    value: 'success',
  },
]

export const initialAssessmentStepper = [
  {
    title: 'Welcome Owner',
  },
  {
    title: 'Create Profile',
  },
  {
    title: 'Sms Subscription',
  },
  {
    title: 'Sms Unsubscribe',
  },
  {
    title: 'Questions',
  },
  {
    title: 'Terrific',
  },
]

export const assessmentsStepper = [
  {
    title: 'Questions',
  },
  {
    title: 'Terrific',
  },
]

export const formKeys = {
  [FORM.PROFILE_FORM]: ['firstName', 'lastName', 'mobileContactNumber'],
  [FORM.ADVISOR_PROFILE_FORM]: ['firstName', 'lastName', 'email', 'mobileContactNumber'],
  [FORM.USER_PROFILE]: ['email', 'firstName', 'mobileContactNumber'],
  [FORM.BUSINESS_PROFILE]: ['businessName', 'yearsInBusiness'],
  [FORM.USER_PROFILE_FORM]: ['firstName', 'lastName', 'email', 'mobileContactNumber', 'advisors'],
  [FORM.USER_MANAGEMENT_BUSINESS_FORM]: [
    'businessName',
    'yearsInBusiness',
    'foodSubIndustryOptionIds',
    'servicesSubIndustryOptionId',
    'businessClassificationOptionId',
    'strongBusinessAreasOptionIds',
    'businessLocationsOptionIds',
    'supportAndHelpOptionIds',
    'businessStageOptionId',
    'topGoalOptionId',
  ],
  [FORM.OWNERS]: ['owners'],
  [FORM.TOPGOAL]: ['topGoal'],
  [FORM.OTHER_GOALS]: ['otherGoals'],
  [FORM.BUSINESS_DETAILS]: ['businessVision', 'challenges'],
  [FORM.BUSINESS_OTHER_DETAILS]: [
    'businessVision',
    'challenges',
    'otherGoalsOptionIds',
    'topGoalOptionId',
    'strongBusinessAreasOptionIds',
    'supportAndHelpOptionIds',
  ],
  [FORM.CALENDAR]: ['description', 'attendees'],
  [FORM.USER_AVAILABILITY]: ['duration', 'timeSlots', 'timeZone'],
  [FORM.MEETING_SCHEDULED]: [
    'meetingTitle',
    'meetingLocation',
    'ignoreConflicts',
    'meetingLocationTool',
  ],
  [FORM.MONTHLY_ATTESTATION]: [
    'registerBusinessStructureOptionId',
    'year',
    'businessBankAccount',
    'revenue',
    'contractors',
    'volunteers',
    'fullTimeEmployees',
    'jobsCreated',
    'wages',
    'month',
    'partTimeEmployees',
    'volunteers',
    'gender',
    'race',
    'debtFinancing',
    'education',
    'financialGrowth',
    'newCustomer',
    'productSale',
    'serviceSale',
    'physicalLocation',
    'targetAudience',
    'additionalInfo',
  ],
  [FORM.BUSINESSES_PROFILE]: ['businessName', 'yearsInBusiness'],
  [FORM.USER_MANAGEMENT_FORM]: [
    'businessName',
    'businessClassificationOptionId',
    'foodSubIndustryOptionIds',
    'servicesSubIndustryOptionId',
  ],
  [FORM.CLIENT_BUSINESS_PROFILE]: [
    'businessName',
    'yearsInBusiness',
    'foodSubIndustryOptionIds',
    'servicesSubIndustryOptionId',
    'businessClassificationOptionId',
    'businessLocationsOptionIds',
    'businessEmail',
    'domainName',
    'registerBusinessStructureOptionId',
    'month',
    'year',
    'partTimeEmployees',
    'fullTimeEmployees',
    'contractors',
    'volunteers',
    'businessStageOptionId',
  ],
  [FORM.USER_ACCOUNTS_BUSINESS_FORM]: [
    'EIN',
    'year',
    'month',
    'naicsCode',
    'domainName',
    'volunteers',
    'contractors',
    'businessName',
    'businessEmail',
    'yearsInBusiness',
    'topGoalOptionId',
    'partTimeEmployees',
    'fullTimeEmployees',
    'businessStageOptionId',
    'businessNatureOptionId',
    'supportAndHelpOptionIds',
    'foodSubIndustryOptionIds',
    'businessLocationsOptionIds',
    'strongBusinessAreasOptionIds',
    'servicesSubIndustryOptionId',
    'businessClassificationOptionId',
    'registerBusinessStructureOptionId',
  ],
}

export const ASSESSMENTS = {
  sales_level_retail_1: {
    tag: 'Sales',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_level_ecommerce_1: {
    tag: 'Sales',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  business_essentials_retail_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_retail_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_retail_3: {
    tag: 'Business Essentials',
    title: 'Business Essentials 3',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 3,
  },
  business_essentials_ecommerce_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_ecommerce_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_ecommerce_3: {
    tag: 'Business Essentials',
    title: 'Business Essentials 3',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 3,
  },
  sales_assessment: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment',
    bgColor: 'bg-tertiary',
    category: 'Sales',
  },
  initial: {
    tag: 'Initial Assessment',
    title: 'Initial Assessment',
    bgColor: 'bg-tertiary',
    category: 'Initial',
  },
  baseline_assessment: {
    tag: 'Business Baseline',
    title: 'Business Baseline',
    bgColor: 'bg-yellow-darker',
    category: 'Baseline',
  },
  business_essentials_food_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_restaurant_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_restaurant_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_mobile_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_mobile_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_kitchen_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_kitchen_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_services_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_services_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  business_essentials_childcare_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 1,
  },
  business_essentials_childcare_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
    level: 2,
  },
  finance_lvl_retail_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_retail_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  finance_lvl_restaurant_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_kitchen_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_kitchen_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  finance_lvl_mobile_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_mobile_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  finance_lvl_services_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_services_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  finance_lvl_childcare_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_childcare_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  finance_lvl_ecommerce_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 1,
  },
  finance_lvl_ecommerce_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
    level: 2,
  },
  sales_lvl_retail_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_restaurant_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_kitchen_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_mobile_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_childcare_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_ecommerce_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  sales_lvl_services_1: {
    tag: 'Sales Assessment',
    title: 'Sales Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
    level: 1,
  },
  marketing_lvl_retail_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_restaurant_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_kitchen_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_mobile_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_ecommerce_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_childcare_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_services_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_services_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_childcare_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_retail_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_restaurant_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_kitchen_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_mobile_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
  marketing_lvl_ecommerce_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
    level: 1,
  },
}

export const assessmentIcons = [
  { iconLabel: 'Retail', value: RetailIcon },
  { iconLabel: 'ECommerce', value: EcommerceIcon },
  { iconLabel: 'Food', value: FoodIcon },
  { iconLabel: 'Childcare', value: ChildCareIcon },
  { iconLabel: 'Services', value: ServicesIcon },
]

export const activeQuestion = {
  id: 'abdd2775-ad45-4fa4-b01f-629201c4e6fbb',
  tenantId: null,
  previous: 'abdd2775-ad45-4fa4-b01f-629201c4e6faa',
  assessmentType: 'initial',
  meta: {
    showToBusinessProfile: true,
    showToAdmin: true,
    showToOwner: true,
  },
  content: {
    owner: {
      description: 'Where is your business located?',
      title: '',
    },
    businessProfile: {
      description: 'Business Locations',
      title: '',
    },
    admin: {
      description: 'Where is the business located?',
      title: '',
    },
  },
  optionType: 'select',
  options: [
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o1',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff20',
      value: 'Home-Based',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o2',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff21',
      value: 'Retail Location',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o3',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff22',
      value: 'Commercial Space',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o4',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff23',
      value: 'Incubator / Innovation Center',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o5',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff24',
      value: 'Other',
    },
  ],
  createdAt: null,
  updatedAt: null,
}

export const filterByTimeOption: any = [
  {
    label: 'This Week (Mon-Sun)',
    value: 'this week',
  },
  {
    label: 'This Month',
    value: 'this month',
  },
  {
    label: 'This Year',
    value: 'this year',
  },
  {
    label: 'All Time',
    value: 'all time',
  },
]

export const clientActivityIcons = [
  { iconLabel: 'Accounts', value: AccountsCreatedIcon },
  { iconLabel: 'Signin', value: SignInIcon },
  { iconLabel: 'MeetingSchedule', value: MeetingScheduleIcon },
  { iconLabel: 'MeetingReschedule', value: MeetingRescheduleIcon },
]

export const stepNames = [
  { name: 'guide', title: 'Guide' },
  { name: 'setup', title: 'Set Up' },
  { name: 'use', title: 'Use' },
]

export const sortOptions = [
  { label: 'Sort By name', value: 'name' },
  { label: 'Sort By Business name', value: 'businessName' },
  { label: 'Sort By Created', value: 'created' },
  { label: 'Sort By Activated', value: 'activated' },
]

export const MEDIA_BUCKET = `https://${process.env.REACT_APP_MEDIA_BUCKET}`

export const enum USER_MANAGEMENT_TAB {
  BUSINESSES = 'businesses',
  ADVISORS = 'advisors',
}

export const tabsOptions = [
  {
    label: 'Businesses',
    link: USER_MANAGEMENT_TAB.BUSINESSES,
  },
  {
    label: 'Advisors',
    link: USER_MANAGEMENT_TAB.ADVISORS,
  },
]

export const SUBTITLE = 'Monthly Impact Reports'

export enum DATA_REPORTS_TABS {
  DATA = 'Data',
  REPORTS = 'Reports',
  INDUSTRY_REPORT = 'Industry Report',
}

export const DataReportsTabs = [
  {
    label: DATA_REPORTS_TABS.DATA,
    link: DATA_REPORTS_TABS.DATA.toLowerCase(),
    isVisible: true,
  },
  {
    label: DATA_REPORTS_TABS.REPORTS,
    link: DATA_REPORTS_TABS.REPORTS.toLowerCase(),
    isVisible: true,
  },
  {
    label: DATA_REPORTS_TABS.INDUSTRY_REPORT,
    link: DATA_REPORTS_TABS.INDUSTRY_REPORT.toLowerCase().replace(/\s+/g, '_'),
    isVisible: true,
  },
]

export const actionOption = [
  { action: 'active', label: 'Reset Password', value: 'resetpassword' },
  { action: 'active', label: 'Chat', value: 'chat' },
  { action: 'inactive', label: 'Send Invitation', value: 'sendinvitation' },
  { action: 'invited', label: 'Resend Invitation', value: 'resendinvitation' },
]

export const clientTabs = [
  { name: 'details', title: 'Details' },
  { name: 'meetingRecord', title: 'Meeting Record' },
  { name: 'assessmentTools', title: 'Assessments, Plays, & Tools' },
  { name: 'activity', title: 'Activity' },
  { name: 'businessData', title: 'Business Data' },
]

export const userStatus = {
  all: 'All',
  invited: 'Invited',
  active: 'Active',
  // inactive: 'InActive',
  // pending: 'Pending',
  disabled: 'Disabled',
}
export const userStatusPillColor = {
  active: PillColorTheme.SUCCESS,
  disabled: PillColorTheme.DISABLE,
  invited: PillColorTheme.INVITED,
}

export const statusOptions = Object.keys(userStatus).map((key) => ({
  label: userStatus[key],
  value: key,
}))

export const integrationLogActions = {
  Squareup: 'Integrated Square POS',
  QuickBooks: 'Integrated QuickBooks',
}

export const fileExtension = {
  powerpoint: ['pps', 'ppt', 'pptx'],
  excel: ['ods', 'xls', 'xlsm', 'xlsx'],
  word: ['doc', 'docx', 'odt', 'msword'],
  csv: ['csv'],
  pdf: ['pdf'],
  video: [
    '3g2',
    '3gp',
    'avi',
    'flv',
    'h264',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    'wmv',
  ],
  image: ['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff'],
}

export const fileIcons = [
  { iconLabel: 'presentation', value: MsPowerPointIcon },
  { iconLabel: 'excel', value: MsExcelIcon },
  { iconLabel: 'word', value: MsWordIcon },
  { iconLabel: 'csv', value: CsvIcon },
  { iconLabel: 'video', value: VideoIcon },
  { iconLabel: 'image', value: ImageIcon },
  { iconLabel: 'document', value: GenericDocumentIcon },
  { iconLabel: 'pdf', value: PdfIcon },
]

export const defaultFileIcon = { iconLabel: 'document', value: GenericDocumentIcon }

export const ADVISOR_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
  INVITED: 'invited',
}

export const playBookTabsOptions = [
  { name: 'inProgress', title: 'In-Progress' },
  { name: 'completed', title: 'Completed' },
]

export const playTabsOptions = [
  { name: 'inProgress', title: 'In-Progress' },
  { name: 'notStarted', title: 'Not Started' },
  { name: 'completed', title: 'Completed' },
]

export const playColors = [
  {
    label: 'All',
    value: 'All',
    color: 'bg-secondary',
    name: 'business essentials',
    colorCode: '#5F5F64',
    backgroundColor: 'bg-cream-dark',
    textColor: 'text-primary-text',
    borderColor: 'border-primary-outline',
    completedCardBackgroundColor: 'bg-secondary',
  },
  {
    label: 'Sales',
    value: 'Sales',
    color: 'bg-sky-light',
    name: 'sales',
    colorCode: '#77FADB',
    backgroundColor: 'bg-sky-light',
    textColor: 'text-green-light',
    borderColor: 'border-aqua',
    completedCardBackgroundColor: 'bg-white',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
    color: 'bg-yellow',
  },
  {
    label: 'Financing',
    value: 'Financing',
    color: 'bg-sky',
  },
  {
    label: 'Operations',
    value: 'Operations',
    color: 'bg-sky-dark',
  },
  {
    label: 'Team',
    value: 'Team',
    color: 'bg-sky-darker',
  },
]

export const toolsTabs = [
  {
    label: 'My Tools',
    link: 'myTools',
  },
  {
    label: 'All Tools',
    link: 'allTools',
  },
]

export enum IDataTabs {
  OVERVIEW = 'Overview',
  CUSTOMERS = 'Customers',
  MARKETING = 'Marketing',
  FINANCE = 'Finance',
  PRODUCT = 'Product',
}

export enum IChartNames {
  TOP_SELLING_PRODUCTS = 'TOP_SELLING_PRODUCTS',
  SALES_GROWTH_RATE = 'SALES_GROWTH_RATE',
  TOP_GROSS_SALES_BY_CHANNEL = 'TOP_GROSS_SALES_BY_CHANNEL',
  GROSS_SALES_SUMMARY = 'GROSS_SALES_SUMMARY',
}

export const dataTabs = [
  { label: IDataTabs.OVERVIEW, link: IDataTabs.OVERVIEW.toLowerCase() },
  // { label: IDataTabs.CUSTOMERS, link: IDataTabs.CUSTOMERS.toLowerCase() },
  // { label: IDataTabs.MARKETING, link: IDataTabs.MARKETING.toLowerCase() },
  { label: IDataTabs.FINANCE, link: IDataTabs.FINANCE.toLowerCase() },
  { label: IDataTabs.PRODUCT, link: IDataTabs.PRODUCT.toLowerCase() },
]

export const allTools = {
  calendly: [{ name: 'Calendly', url: 'https://calendly.com/event_types/user/me' }],
  sales: [
    {
      name: 'QuickBooks',
      url: 'https://app.qbo.intuit.com',
    },
    {
      name: 'Squareup',
      url: 'https://squareup.com/dashboard',
    },
  ],
}

export const toolsImage = [
  { iconLabel: 'QuickBooks', value: QuickBookIcon },
  { iconLabel: 'Squareup', value: SquareIcon },
  { iconLabel: 'Calendly', value: CalendlyIcon },
  { iconLabel: 'Google Calendar', value: GoogleCalendarIcon },
  { iconLabel: 'Outlook', value: OutLookIcon },
]
export const meetingTabOptions = [
  {
    label: 'Upcoming',
  },
  {
    label: 'Previous',
  },
]

export const AccountTabsOptions = [
  {
    label: 'My Profile',
  },
  {
    label: 'Business Profile',
  },
]
export const AccountTabOptions = [
  {
    label: 'My Profile',
  },
]

export const meetingCheckBoxOption = [
  {
    label: 'As soon as possible',
    value: 'as soon as possible',
    id: 1,
    isChecked: false,
  },
  {
    label: 'I‘m flexible',
    value: 'i‘m flexible',
    id: 2,
    isChecked: false,
  },
]

export const calendarOptions = [
  {
    label: 'Day',
    value: Views.DAY,
  },
  {
    label: 'Week',
    value: Views.WEEK,
  },
  {
    label: 'Month',
    value: Views.MONTH,
  },
]

export const slots = {
  defaultStartTime: '08:00',
  defaultEndTime: '17:00',
}

export const defaultValues = {
  timeSlots: [
    {
      label: 'Sunday',
      value: 'Sunday',
      checked: false,
      slots: [],
    },
    {
      label: 'Monday',
      value: 'Monday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Tuesday',
      value: 'Tuesday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Wednesday',
      value: 'Wednesday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Thursday',
      value: 'Thursday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Friday',
      value: 'Friday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Saturday',
      value: 'Saturday',
      checked: false,
      slots: [],
    },
  ],
}

export const customLabels = {
  second: {
    past: {
      one: '{0} second ago',
      other: '{0} seconds ago',
    },
    future: {},
  },
  minute: {
    past: {
      one: '{0} min ago',
      other: '{0} min ago',
    },
    future: {},
  },
  hour: {
    past: {
      one: '{0} hr ago',
      other: '{0} hrs ago',
    },
    future: {},
  },
  day: {
    past: {
      one: '{0} day ago',
      other: '{0} days ago',
    },
    future: {},
  },
  week: {
    past: {
      one: '{0} week ago',
      other: '{0} weeks ago',
    },
    future: {},
  },
  month: {
    past: {
      one: '{0} month ago',
      other: '{0} months ago',
    },
    future: {},
  },
  year: {
    past: {
      one: '{0} year ago',
      other: '{0} years ago',
    },
    future: {},
  },
}
export const defaultDuration = [{ label: '60 minutes', duration: '60' }]

export const PathNames = {
  Data: 'data',
  Clients: 'clients',
  'My Tools': 'tools',
  Calendar: 'calendar',
  Playbooks: 'playbooks',
  Dashboard: 'dashboard',
  'My Business': 'dashboard',
  'Manage Plays': 'manage-plays',
  'Data & Reports': 'data-reports',
  'User Management': 'user-management',
}

export enum CALENDAR {
  MICROSOFT_OFFICE_365 = 'MICROSOFT OFFICE 365',
  GOOGLE_CALENDAR = 'GOOGLE CALENDAR',
  INTERNAL = 'INTERNAL',
}
export const CalendarColorsMap = new Map<CALENDAR, string>([
  [CALENDAR.GOOGLE_CALENDAR, 'rgb(255, 244, 204)'],
  [CALENDAR.MICROSOFT_OFFICE_365, 'rgb(220, 240, 254)'],
  [CALENDAR.INTERNAL, 'rgb(223, 205, 241)'],
])

export const CalendarTailwindColorsMap = new Map<CALENDAR, string>([
  [CALENDAR.GOOGLE_CALENDAR, 'yellow'],
  [CALENDAR.MICROSOFT_OFFICE_365, 'sky'],
  [CALENDAR.INTERNAL, 'purple-lighter2x'],
])

export const calendarLabelMap = {
  [CALENDAR.GOOGLE_CALENDAR]: 'Google Calendar',
  [CALENDAR.MICROSOFT_OFFICE_365]: 'Outlook Calendar',
}

export const reschedule = '# meetings re-scheduled'

export const playData = {
  playTabs: [
    {
      tabData: {
        image: '',
        steps: [
          {
            stepData: [
              {
                stepHeading: {
                  title: '',
                  description: '',
                },
                stepBody: [
                  {
                    optionType: 'button',
                  },
                ],
                stepType: 'question',
              },
            ],
          },
        ],
        pricing: '',
      },
      tabName: 'Guide',
    },
    {
      tabData: {
        image: '',
        steps: [
          {
            stepData: [
              {
                stepType: 'html',
              },
              {
                stepType: 'next',
              },
            ],
            playType: 'Education',
          },
        ],
        pricing: '',
      },
      tabName: 'Setup',
    },
    {
      playType: '',
      tabData: {
        image: '',
        steps: [
          {
            stepData: [
              {
                stepType: 'success',
              },
              {
                stepType: 'calandar-meeting-scheduling',
              },
              {
                stepType: 'play-complete-button',
              },
            ],
          },
        ],
      },
      tabName: 'Use',
    },
  ],
}

export const playStage = [
  {
    steps: [
      {
        isCompleted: false,
      },
    ],
  },
  {
    steps: [
      {
        isCompleted: false,
      },
      {
        isCompleted: false,
      },
      {
        isCompleted: false,
      },
    ],
  },
  {
    steps: [
      {
        isCompleted: false,
      },
    ],
  },
]

const initial = [
  'topGoal',
  'businessStage',
  'supportAndHelp',
  'foodSubIndustry',
  'servicesSubIndustry',
  'strongBusinessAreas',
  'businessClassification',
  'businessLocations',
]
const baseline = [
  'registerBusinessStructure',
  'businessNature',
  'employees',
  'businessStarted',
  'volunteers',
  'thisYearRevenue',
  'lastYearRevenue',
  'businessRevenue',
  'hasEmployees',
  'workAloneOrWithOthers',
]
export const attestationFields = [
  'employees',
  'jobsCreated',
  'volunteers',
  'debtFinancing',
  'wages',
  'physicalLocation',
  'targetAudienceSize',
  'services',
  'products',
  'customer',
  'profitAndLoss',
  'bankAccount',
  'registerBusiness',
]
const businessEssentials = [
  'hasEin',
  'isBusinessRegistered',
  'businessPlanHelp',
  'businessPlan',
  'businessStage',
  'hasDomainName',
  'hasEsignatureTool',
  'hasGoogleWorkspace',
  'hasLogo',
  'hasBusinessEmail',
  'hasGoogleChrome',
  'hasBusinessPhoneNumber',
  'hasLogoTrademarked',
  'registerBusinessStructure',
  'hasBusinessBankAccount',
  'googleWorkspacePlays',
  'googleBusinessProfilePlays',
  'hasGoogleBusinessProfile',
]
const financeEssentials = [
  'hasFiledTaxes',
  'hasBookKeepingTool',
  'hasUsingBookkeeping',
  'bookKeepingTool',
  'quickBookPlays',
  'accountingMethod',
  'hasBudget',
]
const salesEssentials = [
  'POS',
  'hasPOS',
  'stripePlays',
  'paymentApps',
  'squarePlays',
  'paymentTypes',
  'hasQuickBooks',
  'paymentProcessor',
  'hasMoreLocations',
  'pricingStrategies',
  'productsAndServices',
  'hasPaymentProcessor',
  'hasBusinessEmployees',
  'learnPricingStrategies',
]
export const assessmentOption = {
  initial: initial,
  baseline_assessment: baseline,
  business_essentials: businessEssentials,
  finance_lvl: financeEssentials,
  sales_lvl: salesEssentials,
}
export const ImpactReportData = {
  additionalInfo: 'additionalInfo',
  businessBankAccount: 'businessBankAccount',
  employeesContractors: 'contractors',
  debtFinancing: 'debtFinancing',
  educationLevel: 'education',
  profitLoss: 'financialGrowth',
  employeesFullTime: 'fullTimeEmployees',
  gender: 'gender',
  jobsCreated: 'jobsCreated',
  businessStructureMonth: 'month',
  newCustomers: 'newCustomer',
  employeesPartTime: 'partTimeEmployees',
  locations: 'physicalLocation',
  salesProducts: 'productSale',
  race: 'race',
  businessStructure: 'registerBusinessStructure',
  profitLossAmount: 'revenue',
  salesServices: 'serviceSale',
  targetAudience: 'targetAudience',
  employeesVolunteers: 'volunteers',
  wagesAveragePerHour: 'wages',
  businessStructureYear: 'year',
}

export const attestationImpactReportText = {
  signedText:
    'Thanks for submitting this month’s impact statement! We’ve shared it with your advisor.',
  dueDatePassedText: 'The signing period for the impact statement ended for this month.',
  reminderText: 'We’ll send you reminders again next month!',
}

export const industry = {
  foodId: 'a83c31b3-86d7-46e8-bc26-25dda701ff12',
  servicesId: 'a83c31b3-86d7-46e8-bc26-25dda701ff14',
}

export const zendeskHelpLink = 'https://gotackle.zendesk.com/hc/en-us'
export const supportedPlayIds = ['Createbe9e-4d7c-434e-9ff1-291621e3flogo']

export const pathName = {
  playBooksCategory: 'playbooks-category',
}

const today = new Date()
today.setDate(today.getDate() + 1)
const tomorrow = today.toISOString().split('T')[0]

export const meetingData = {
  advisor: [
    {
      id: '1',
      firstName: 'test',
      lastName: 'test',
      email: 'test@mailinator.com',
      schedulingUrl: null,
    },
    {
      id: '2',
      firstName: 'newAdvisor',
      lastName: 'nn',
      email: 'newadvisor@mailinator.com',
      schedulingUrl: null,
    },
  ],
  successfulUserAvailabilityHoursAction: {
    duration: ['60'],
  },
  successfulUserAvailabilityHoursResponse: {
    availabilityHours: {
      duration: ['60'],
      timeSlots: [
        {
          label: '',
          slots: [
            {
              startTime: `${tomorrow}T17:30:00Z`,
              endTime: `${tomorrow}T18:30:00Z`,
            },
            {
              startTime: `${tomorrow}T18:00:00Z`,
              endTime: `${tomorrow}T19:00:00Z`,
            },
          ],
          value: '',
        },
      ],
    },
  },
  successfulUserAvailabilityResponse: {
    duration: '60',
    availability: [
      {
        date: tomorrow,
        slots: [
          {
            isAvailable: true,
            slotStartTime: `${tomorrow}T17:30:00Z`,
            slotEndTime: `${tomorrow}T18:30:00Z`,
          },
          {
            isAvailable: true,
            slotStartTime: `${tomorrow}T18:00:00Z`,
            slotEndTime: `${tomorrow}T19:00:00Z`,
          },
        ],
      },
    ],
  },
}

export const availabilityStatus = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed',
}

export const CALENDARS_LIST: ICalendarList[] = [
  {
    name: 'GoTackle Calendar',
    color: '#DFCDF1',
    hasConnectionError: false,
    toolName: '',
    isConnected: true,
  },
  {
    name: 'Outlook Calendar',
    color: '#DCF0FE',
    hasConnectionError: false,
    toolName: CYCLR_CALENDAR.MICROSOFT_OFFICE_365,
    isConnected: false,
  },
  {
    name: 'Google Calendar',
    color: '#FFF4CC',
    hasConnectionError: false,
    toolName: CYCLR_CALENDAR.GOOGLE_CALENDAR,
    isConnected: false,
  },
]
export const skipRecommendationForAssessmentTypes = ['initial', 'baseline_assessment']

export const ToolFlyOutOptions = [
  {
    title: 'Learn More',
    name: TOOL_FLYOUT_OPTIONS.LEARN_MORE,
    isVisible: true,
  },
  {
    title: 'Plays',
    name: TOOL_FLYOUT_OPTIONS.PLAYS,
    isVisible: true,
  },
  // {
  //   title: 'Diagnostics',
  //   name: TOOL_FLYOUT_OPTIONS.DIAGNOSTICS,
  //   isVisible: true,
  // },
  {
    title: 'Help',
    name: TOOL_FLYOUT_OPTIONS.HELP,
    isVisible: true,
  },
]

export const timeDurationAvailabilityOptions: ITimeDurationAvailabilityOptions[] = [
  { label: '15 min.', value: '15' },
  { label: '30 min.', value: '30' },
  { label: '45 min.', value: '45' },
  { label: '60 min.', value: '60' },
]

export const industrySupportData = [
  {
    Icon: InfoTipIcon,
    heading: 'Example:',
    text: 'I want to know the industry revenue trends for a dog walker and pet sitter.',
    isVisible: true,
  },
  {
    Icon: InfoTipIcon,
    heading: 'Example:',
    text: 'What’s the industry profit margin for a landscaping business?',
    isVisible: true,
  },
  {
    Icon: InfoTipIcon,
    heading: 'Example:',
    text: 'I want to learn more about wages as they relate to retail gift shops.',
    isVisible: false,
  },
]
export const GOOGLE_CLOUD_URL = 'https://storage.cloud.google.com'

export const API_TYPES = {
  UPDATE_ADVISOR_PROFILE: storeTypes.UPDATE_ADVISOR_PROFILE.REJECTED.replace('_REJECTED', ''),
  UPDATE_OWNER_PROFILE: storeTypes.UPDATE_OWNER_PROFILE.REJECTED.replace('_REJECTED', ''),
  SAVE_ASSESSMENT_RESPONSE: storeTypes.SAVE_ASSESSMENT_RESPONSE.REJECTED.replace('_REJECTED', ''),
}

export const ACTION_TYPE = {
  OnChange: 'onChange',
}

export const ERROR_MESSAGE = {
  BUSINESS_PROFILE_KEY_NOT_FOUND: 'Error getting Business Profile record. Try again later.',
  NEXT_NOT_FOUND: 'Assessment next not found.',
  SELECT_ANSWER_AGAIN: 'Please select your answer again.',
}
