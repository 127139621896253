import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import Clients from 'components/Clients'
import {
  getClientActivityLogsAction,
  getClientActivityScoreAction,
  getClientProfileAction,
  getClientProfileDetailsAction,
  getAdvisorDashboardAction,
  getTopActiveClientsAction,
  updateClientProfileDetailAction,
  getClientEditProfileDetailAction,
  openClientDetailAction,
  getAssessmentAction,
  getAssessmentListAction,
  getConnectedToolsAction,
  getTackleMeetingEventsAction,
  showCommentsAction,
  getClientAttestationReportAction,
  resetClientsAction,
  resetSavedCurrentPageAction,
  cancelTackleMeetingEventAction,
  getClientRecommendedPlaysAssessments,
} from 'store/actions/clients'
import {
  getClientActivityLogs,
  getClientActivityScoreCards,
  getClientDetailsAssessmentSelector,
  getClientsActivity,
  getCommentsValueSelector,
  getGoalsSelector,
  getOtherDetailsListSelector,
  getTackleMeetingSelector,
} from 'store/selectors/clients'
import { getAssessmentListSelector } from 'store/selectors/assessmentsList'
import TopActiveClients from 'components/Clients/TopActiveClients'
import Client from 'components/Clients/Client'
import ClientDetails from 'components/Clients/Client/Details'
import ClientActivity from 'components/Clients/Client/Activity'
import ClientPlaybook from 'components/Clients/Client/ClientPlaybook'
import BusinessData from 'components/Clients/Client/BusinessData'
import {
  getUserFilesAction,
  resetErrorAction,
  saveUserFileAction,
  uploadFileAction,
} from 'store/actions/common'
import Files from 'components/Clients/Client/Details/Files'
import {
  getAssessmentOptionsSelector,
  getAssessmentResponseSelector,
  getBusinessProfileAssessmentsSelector,
} from 'store/selectors/owner'
import {
  getAssessmentOptionsAction,
  getUserAssessmentResponseAction,
} from 'store/actions/owner/initialAssessment'
import { getUserBusinessProfileAction, resendUserInviteAction } from 'store/actions/user'
import BusinessProfile from 'components/Clients/Client/Details/BusinessProfile'
import EditClient from 'components/Clients/Client/Details/EditClient'
import {
  getBusinessAndAssessmentResponseAction,
  getUserProfileAndAdvisorsAction,
} from 'store/actions/userManagement'
import EditOtherDetails from 'components/Clients/Client/Details/EditClient/EditOtherDetails'
import UpcomingMeeting from 'components/Clients/Client/Meeting/UpcomingMeeting'
import {
  openMeetingConfirmationModalBoxAction,
  updateCalendarMeetingAction,
  updateUserCalendarEventAction,
} from 'store/actions/calendar'
import Comments from 'components/Clients/Client/Meeting/Comments'
import MeetingEvents from 'components/Clients/Client/Meeting/MeetingEvents'
import AddComments from 'components/Clients/Client/Meeting/AddComments'
import DateTime from 'components/Clients/Client/Meeting/DateTime'
import EventDetail from 'components/Clients/Client/Meeting/EventDetail'
import UpComingMeetingMobileView from 'components/Clients/Client/Meeting/UpComingMeetingMobileView'
import OtherDetails from 'components/Clients/Client/Details/OtherDetails'
import UserProfile from 'components/Clients/Client/Details/UserProfile'
import ClientReportTable from 'components/DataReports/ClientReportManagement/ClientReportTable'
import EditBusinessProfile from 'components/Common/EditBusinessProfile'
import EditUserProfile from 'components/Common/EditUserProfile'
import { RootState } from 'App'
import Assessments from 'components/Clients/Client/ClientPlaybook/Assessments'
import Tools from 'components/Clients/Client/ClientPlaybook/Tools'
import EditClientDetailsBusinessProfile from 'components/Clients/Client/Details/EditClient/EditClientDetailsBusinessProfile'
import Plays from 'components/Clients/Client/ClientPlaybook/Plays'
import AssessmentsAndPlays from 'components/Clients/Client/ClientPlaybook/AssessmentsAndPlays'
import { openConfirmationModalAction } from 'store/actions/user'

/* -------------------------- INTERFACES --------------------------- */

export interface IBusinessClassification {
  businessClassification: string
  businessClassificationOptionId: string
}

export interface IBusiness {
  businessProfile: IBusinessClassification
}
export interface ICLient {
  client: IBusiness
}

/* -------------------------- CLIENTS --------------------------- */
const ClientsProps = (state: any) => {
  const { list, isAllClientFetching } = state.clients
  const { isAdvisorDashboardLoading } = state.loadingStatus
  return {
    clientList: list,
    clientData: getClientsActivity(state),
    isAdvisorDashboardLoading,
    isAllClientFetching,
  }
}

function ClientsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAdvisorDashboardAction: getAdvisorDashboardAction.STARTED,
    },
    dispatch
  )
}
export const ClientsContainer = connect(ClientsProps, ClientsDispatch)(Clients)

/* -------------------------- Top Active CLIENTS --------------------------- */
const TopActiveClientsProps = (state: any) => {
  const { topActiveClients } = state.clients
  return {
    topActiveClients,
  }
}

function TopActiveClientsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getTopActiveClientsAction: getTopActiveClientsAction.STARTED,
    },
    dispatch
  )
}
export const TopActiveClientsContainer = connect(
  TopActiveClientsProps,
  TopActiveClientsDispatch
)(TopActiveClients)

/* -------------------------- CLIENT DETAILS --------------------------- */
const ClientDetailsProps = (state: any) => {
  const { client } = state.clients
  return {
    activity: client?.activity,
    activityLogs: client?.activityLogs,
    profile: client?.profile,
    businessProfile: client?.businessProfile,
  }
}

function ClientDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientProfileAction: getClientProfileAction.STARTED,
      resetClientsAction: resetClientsAction,
      openConfirmationModalAction: openConfirmationModalAction,
      resendUserInviteAction: resendUserInviteAction.STARTED,
    },
    dispatch
  )
}

export const ClientDetailsContainer = connect(ClientDetailsProps, ClientDetailsDispatch)(Client)
/* -------------------------- CLIENT DETAILS USER PROFILE--------------------------- */
const ClientDetailsUserProfileProps = (state: any) => {
  const { client } = state.clients
  const { clientDetailsLoading } = state.loadingStatus

  return {
    clientDetailsLoading,
    userProfile: client?.profile,
  }
}

function ClientDetailsUserProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientProfileAction: getClientProfileAction.STARTED,
    },
    dispatch
  )
}

export const ClientDetailsUserProfileContainer = connect(
  ClientDetailsUserProfileProps,
  ClientDetailsUserProfileDispatch
)(UserProfile)
/* -------------------------- CLIENT OTHER DETAILS --------------------------- */
const ClientOtherDetailsProps = (state: any) => {
  const { businessProfile } = state.clients.client
  const { clientDetailsLoading, clientBusinessProfileLoading } = state.loadingStatus
  return {
    clientDetailsLoading,
    clientBusinessProfileLoading,
    businessProfile,
    otherDetailsList: getOtherDetailsListSelector(state),
    assessmentResponse: getAssessmentResponseSelector(state),
  }
}

function ClientOtherDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientProfileAction: getClientProfileAction.STARTED,
    },
    dispatch
  )
}

export const ClientOtherDetailsContainer = connect(
  ClientOtherDetailsProps,
  ClientOtherDetailsDispatch
)(OtherDetails)

/* -------------------------- CLIENT ACTIVTY --------------------------- */
const ClientActivityProps = (state: any) => {
  const { client } = state.clients
  const { scoreCardLoading, clientActivityLogLoading } = state.loadingStatus
  return {
    scoreCardLoading,
    clientActivityLogLoading,
    activity: client?.activity,
    scoreCards: getClientActivityScoreCards(state),
    activityLogs: getClientActivityLogs(state),
  }
}

function ClientActivityDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientActivityLogsAction: getClientActivityLogsAction.STARTED,
      getClientActivityScoreAction: getClientActivityScoreAction.STARTED,
    },
    dispatch
  )
}
export const ClientActivityContainer = connect(
  ClientActivityProps,
  ClientActivityDispatch
)(ClientActivity)

/* --------------------------- EditBusinessProfile --------------------------- */
const editBusinessProfileProps = (state: RootState) => {
  const { client }: ICLient = state.clients
  const { detailsLoading } = state.loadingStatus

  return {
    detailsLoading,
    businessClassificationOptionId: client.businessProfile?.businessClassificationOptionId,
  }
}

function editBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}

export const EditBusinessProfileContainer = connect(
  editBusinessProfileProps,
  editBusinessProfileDispatch
)(EditBusinessProfile)

/* -------------------------- CLIENT BUSINESS DATA --------------------------- */
const ClientBusinessDataProps = (state: RootState) => {
  const { profile } = state.clients.client
  return {
    isConsentGivenToAdvisor: profile.isConsentGivenToAdvisor || false,
    clientId: profile.id,
  }
}

const clientBusinessDataDispatch = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch)
}
export const ClientBusinessDataContainer = connect(
  ClientBusinessDataProps,
  clientBusinessDataDispatch
)(BusinessData)
/*-------------------------CLIENT DETAILS BUSINESS PROFILE-----------------------------*/
const EditClientDetailsBusinessProfileProps = (state: RootState) => {
  const { client }: ICLient = state.clients
  const { detailsLoading, isAssessmentOptionsLoading } = state.loadingStatus

  return {
    detailsLoading,
    isAssessmentOptionsLoading,
    businessClassificationOptionId: client.businessProfile?.businessClassificationOptionId,
    options: getAssessmentOptionsSelector(state),
  }
}

function EditClientDetailsBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAssessmentOptionsAction: getAssessmentOptionsAction.STARTED,
    },
    dispatch
  )
}

export const EditClientDetailsBusinessProfileContainer = connect(
  EditClientDetailsBusinessProfileProps,
  EditClientDetailsBusinessProfileDispatch
)(EditClientDetailsBusinessProfile)
/* -------------------------- CLIENT PLAYBOOK --------------------------- */
const ClientPlaybookProps = (state: any) => {
  const assessmentList = getAssessmentListSelector(state)
  const { clientAssessmentLoading, clientConnectedToolsLoading } = state.loadingStatus
  const { client, clientConnectedTools } = state.clients
  const { businessProfile, user } = state.user
  return {
    connectedTools: clientConnectedTools,
    clientConnectedToolsLoading,
    userId: client.profile.id,
    clientAssessmentLoading,
    businessProfile,
    assessmentList,
    user,
  }
}

const clientPlaybookDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAssessmentListAction: getAssessmentListAction.STARTED,
      getAssessmentAction: getAssessmentAction.STARTED,
      getConnectedToolsAction: getConnectedToolsAction.STARTED,
    },
    dispatch
  )
}
export const ClientPlaybookContainer = connect(
  ClientPlaybookProps,
  clientPlaybookDispatch
)(ClientPlaybook)

/*---------------------------Assessment--------------------------------------- */

const AssessmentsProps = (state: any) => {
  const { user } = state.user
  const { client } = state.clients
  const assessmentList = getAssessmentListSelector(state)
  const { clientAssessmentLoading } = state.loadingStatus
  return {
    user,
    assessmentList,
    businessName: client?.businessProfile?.businessName,
    clientAssessmentLoading,
    userId: client.profile.id,
    clientAssessmentResponse: state.owner.assessmentResponse,
  }
}

const AssessmentsDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAssessmentAction: getAssessmentAction.STARTED,
      getAssessmentListAction: getAssessmentListAction.STARTED,
      getUserAssessmentResponseAction: getUserAssessmentResponseAction.STARTED,
    },
    dispatch
  )
}
export const AssessmentsContainer = connect(AssessmentsProps, AssessmentsDispatch)(Assessments)

/*--------------------------Tools---------------------------------------------*/
const ToolsProps = (state: any) => {
  const { clientConnectedTools, client } = state.clients
  const { clientConnectedToolsLoading } = state.loadingStatus
  return {
    clientId: client?.profile?.id,
    connectedTools: clientConnectedTools,
    clientConnectedToolsLoading,
  }
}
const ToolsDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getConnectedToolsAction: getConnectedToolsAction.STARTED,
    },
    dispatch
  )
}
export const ToolsContainer = connect(ToolsProps, ToolsDispatch)(Tools)

/*--------------------------Plays---------------------------------------------*/
const PlaysProps = (state: any) => {
  const { client, clientAssessmentRecommendations } = state.clients
  return {
    clientId: client?.profile?.id,
    clientAssessmentRecommendations,
    isLoadingRecommendations: state.loadingStatus.clientRecommendations,
  }
}
const PlaysDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClientRecommendedPlaysAssessments: getClientRecommendedPlaysAssessments.STARTED,
      resetClientRecommendedPlaysAssessments: getClientRecommendedPlaysAssessments.RESET,
    },
    dispatch
  )
}
export const PlaysContainer = connect(PlaysProps, PlaysDispatch)(Plays)

/*--------------------------Assements and Plays---------------------------------------------*/
const AssessmentsAndPlaysProps = (state: any) => {
  const { client, clientAssessmentRecommendations } = state.clients
  const assessmentList = getAssessmentListSelector(state)
  const { clientAssessmentLoading } = state.loadingStatus
  return {
    assessmentList,
    businessName: client.businessProfile.businessName,
    clientAssessmentLoading,
    clientId: client.profile.id,
    isLoadingRecommendations: state.loadingStatus.clientRecommendations,
    clientAssessmentRecommendations,
  }
}
const AssessmentsAndPlaysDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAssessmentListAction: getAssessmentListAction.STARTED,
      getClientRecommendedPlaysAssessments: getClientRecommendedPlaysAssessments.STARTED,
      resetClientRecommendedPlaysAssessments: getClientRecommendedPlaysAssessments.RESET,
    },
    dispatch
  )
}
export const AssessmentsAndPlaysContainer = connect(
  AssessmentsAndPlaysProps,
  AssessmentsAndPlaysDispatch
)(AssessmentsAndPlays)

/* -------------------------- CLIENT PROFILE DETAILS  --------------------------- */
const ClientProfileDetailsProps = (state: any) => {
  let { user } = state.user
  const { client, openClientDetail } = state.clients
  const { clientDetailsLoading } = state.loadingStatus

  return {
    userId: user.id,
    openClientDetail,
    clientDetailsLoading,
    profile: client?.profile,
    goals: getGoalsSelector(state),
    businessProfile: client.businessProfile,
  }
}

function ClientProfileDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientProfileDetailsAction: getClientProfileDetailsAction.STARTED,
    },
    dispatch
  )
}
export const ClientProfileDetailsContainer = connect(
  ClientProfileDetailsProps,
  ClientProfileDetailsDispatch
)(ClientDetails)

/* -------------------------- Files  --------------------------- */
const FilesProps = (state: any) => {
  let { user } = state.user
  const { client } = state.clients
  const { uploadedFile, uploadFileLoading, userFiles, uploadFileErrors } = state.common
  return {
    uploadFileErrors,
    streamChatId: user.streamAccessToken,
    userFiles: userFiles.sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
    uploadedFile,
    uploadFileLoading,
    profile: client?.profile,
    businessProfile: client?.businessProfile,
  }
}

function FilesDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserFilesAction: getUserFilesAction.STARTED,
      uploadFileAction: uploadFileAction.STARTED,
      saveUserFileAction: saveUserFileAction.STARTED,
      resetUploadFileAction: uploadFileAction.RESET,
    },
    dispatch
  )
}
export const FilesContainer = connect(FilesProps, FilesDispatch)(Files)

/* -------------------------- Business PROFILE  --------------------------- */
const BusinessProfileProps = (state: any) => {
  const { businessProfile } = state.clients.client
  const { clientBusinessProfileLoading } = state.loadingStatus

  return {
    clientBusinessProfileLoading,
    businessProfile,
    assessmentResponse: getAssessmentResponseSelector(state),
    assessment: getBusinessProfileAssessmentsSelector(state),
    clientDetailsList: getClientDetailsAssessmentSelector(state),
  }
}

function BusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserBusinessProfileAction: getUserBusinessProfileAction.STARTED,
    },
    dispatch
  )
}

export const BusinessProfileContainer = connect(
  BusinessProfileProps,
  BusinessProfileDispatch
)(BusinessProfile)

/* -------------------------- Edit Client  --------------------------- */
const EditClientProps = (state: any) => {
  const { OpenClientDetail, disableButton, client } = state.clients
  const { isUserProfileFormSet, isBusinessProfileFormSet } = state.userManagement
  const { detailsLoading } = state.loadingStatus

  return {
    userId: client?.profile?.id,
    detailsLoading,
    OpenClientDetail,
    disableButton,
    isUserProfileFormSet,
    isBusinessProfileFormSet,
    otherDetailsList: getOtherDetailsListSelector(state),
  }
}

function EditClientDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrorAction: resetErrorAction,
      openClientDetailAction: openClientDetailAction,
      getAssessmentOptionsAction: getAssessmentOptionsAction.STARTED,
      getClientEditProfileDetailAction: getClientEditProfileDetailAction.STARTED,
      updateClientProfileDetailAction: updateClientProfileDetailAction.STARTED,
      getUserProfileAndAdvisorsAction: getUserProfileAndAdvisorsAction.STARTED,
      getBusinessAndAssessmentResponseAction: getBusinessAndAssessmentResponseAction.STARTED,
    },
    dispatch
  )
}

export const EditClientContainer = connect(EditClientProps, EditClientDispatch)(EditClient)

/* -------------------------- Edit Other Details --------------------------- */
const editOtherDetailsProps = (state: any) => {
  return {
    goals: getGoalsSelector(state),
  }
}

function editOtherDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserProfileAndAdvisorsAction: getUserProfileAndAdvisorsAction.STARTED,
      getBusinessAndAssessmentResponseAction: getBusinessAndAssessmentResponseAction.STARTED,
      updateClientProfileDetailAction: updateClientProfileDetailAction.STARTED,
      getClientEditProfileDetailAction: getClientEditProfileDetailAction.STARTED,
    },
    dispatch
  )
}

export const EditOtherDetailsContainer = connect(
  editOtherDetailsProps,
  editOtherDetailsDispatch
)(EditOtherDetails)

/* -------------------------- Edit Client Profile --------------------------- */
const editClientProfileProps = (state: RootState) => {
  const { error, mobileContactError } = state.clients

  return {
    error,
    mobileContactError,
  }
}

function editClientProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrorAction: resetErrorAction,
    },
    dispatch
  )
}

export const EditClientProfileContainer = connect(
  editClientProfileProps,
  editClientProfileDispatch
)(EditUserProfile)

/* -------------------------- Client upcoming meeting--------------------------- */
const upcomingMeetingProps = (state: any) => {
  const { meetingDataLoading } = state.loadingStatus
  return {
    meetingDataLoading,
    meeting: getTackleMeetingSelector(state),
  }
}

function upcomingMeetingDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getTackleMeetingEventsAction: getTackleMeetingEventsAction.STARTED,
    },
    dispatch
  )
}

export const UpcomingMeetingContainer = connect(
  upcomingMeetingProps,
  upcomingMeetingDispatch
)(UpcomingMeeting)
/* -------------------------- Client upcoming meeting--------------------------- */

const MeetingMobileViewProps = (state: any) => {
  return {
    meeting: getTackleMeetingSelector(state),
  }
}

function MeetingMobileViewDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getTackleMeetingEventsAction: getTackleMeetingEventsAction.STARTED,
    },
    dispatch
  )
}

export const UpComingMeetingMobileViewContainer = connect(
  MeetingMobileViewProps,
  MeetingMobileViewDispatch
)(UpComingMeetingMobileView)

/* -------------------------- MeetingEvents--------------------------- */
const meetingEventsProps = (state: any) => {
  const { showComments, isDisabled } = state.clients
  const { cancelMeetingLoading, stepType, errorMessage, isMeetingDeletion } = state.calendar
  const { user } = state.user

  return {
    user,
    stepType,
    isDisabled,
    showComments,
    errorMessage,
    isMeetingDeletion,
    cancelMeetingLoading,
  }
}

function meetingEventsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      showCommentsAction: showCommentsAction,
      cancelTackleMeetingEventAction: cancelTackleMeetingEventAction.STARTED,
      updateCalendarMeetingAction: updateCalendarMeetingAction.STARTED,
      updateUserCalendarEventAction: updateUserCalendarEventAction.STARTED,
      getTackleMeetingEventsAction: getTackleMeetingEventsAction.STARTED,
      openMeetingConfirmationModalBoxAction: openMeetingConfirmationModalBoxAction,
    },
    dispatch
  )
}

export const MeetingEventsContainer = connect(
  meetingEventsProps,
  meetingEventsDispatch
)(MeetingEvents)

/* -------------------------- Add Comments--------------------------- */
const addCommentsProps = (state: any) => {
  const { showComments, ownerIds, updatedComments } = state.clients
  return {
    ownerIds,
    showComments,
    updatedComments,
    clientComments: getCommentsValueSelector(state),
  }
}

function addCommentsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      showCommentsAction: showCommentsAction,
    },
    dispatch
  )
}

export const AddCommentsContainer = connect(addCommentsProps, addCommentsDispatch)(AddComments)

/* -------------------------- Comments--------------------------- */
const commentsProps = (state: any) => {
  const { showComments, ownerIds, updatedComments } = state.clients

  return {
    ownerIds,
    showComments,
    updatedComments,
    clientComments: getCommentsValueSelector(state),
  }
}

function commentsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      showCommentsAction: showCommentsAction,
      updateUserCalendarEventAction: updateUserCalendarEventAction.STARTED,
    },
    dispatch
  )
}

export const CommentsContainer = connect(commentsProps, commentsDispatch)(Comments)

/* -------------------------- EventDetail--------------------------- */
const eventDetailProps = (state: any) => {
  const { showComments, ownerIds } = state.clients

  return {
    ownerIds,
    showComments,
    clientComments: getCommentsValueSelector(state),
    meeting: getTackleMeetingSelector(state),
  }
}

function eventDetailDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      showCommentsAction: showCommentsAction,
      updateUserCalendarEventAction: updateUserCalendarEventAction.STARTED,
    },
    dispatch
  )
}

export const EventDetailContainer = connect(eventDetailProps, eventDetailDispatch)(EventDetail)

/* -------------------------- DateTime--------------------------- */
const dateTimeProps = (state: any) => {
  const { showComments, ownerIds } = state.clients
  return {
    showComments,
    ownerIds,
  }
}

function dateTimeDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      showCommentsAction: showCommentsAction,
    },
    dispatch
  )
}

export const DateTimeContainer = connect(dateTimeProps, dateTimeDispatch)(DateTime)
/* -------------------------- DateTime--------------------------- */
const clientReportTableProps = (state) => {
  const { clientReport, isClientAttestationReportLoading, savedCurrentPage } = state.clients
  return {
    clientReportList: clientReport?.items,
    lastEvaluatedKey: clientReport?.lastEvaluatedKey,
    totalPages: clientReport?.totalPages,
    totalRecords: clientReport?.totalRecords,
    noOfItemsPerPage: clientReport?.noOfItemsPerPage,
    isClientAttestationReportLoading,
    savedCurrentPage,
  }
}

function clientReportTableDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientAttestationReportAction: getClientAttestationReportAction.STARTED,
      resetClientAttestationReportAction: getClientAttestationReportAction.RESET,
      resetSavedCurrentPageAction: resetSavedCurrentPageAction,
    },
    dispatch
  )
}

export const ClientReportTableContainer = connect(
  clientReportTableProps,
  clientReportTableDispatch
)(ClientReportTable)
